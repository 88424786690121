.post {
  &__body-text {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 19px;
    overflow-wrap: break-word;

    @media screen and (min-width: 1100px) {
      font-size: 16px;
      line-height: 130%;
    }
  }

  &__body-text-more-container {
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-end;
  }

  &__body-text-more {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
  }

  &__media-container {
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    // gap: 13px;
    gap: 2%;
  }

  &__body-text-modal {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
  }
}
