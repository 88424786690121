@import '../../../../../styles/utils/variables';
@import '../../../../../styles/utils/mixin';

.PodcastReader {
  &__container {
    width: 320px;
    padding: 12px 12px;
    background-color: $white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    margin: 0 auto;
    @media screen and (min-width: 1100px) {
      width: 1040px;
      padding: 16px 24px;
    }
    .PodcastReader__mobile-arrow-back {
      display: block;
      position: absolute;
      top: 24px;
      left: 16px;
      color: $text-middle;
      transition: $transition;
      cursor: pointer;
      @media screen and (min-width: 1100px) {
        display: none;
      }

      &:hover,
      &:active {
        color: $accent-main;
      }
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    position: relative;
    @media screen and (min-width: 1100px) {
      margin-bottom: 16px;
    }

    > .secondaryButton {
      margin-left: auto;
      margin-right: 24px;
    }

    > svg {
      position: absolute;
      top: -100px;
      left: 0;
      color: $text-middle;
      transition: $transition;
      cursor: pointer;
      @media screen and (min-width: 1100px) {
        top: -60px;
      }

      &:hover,
      &:active {
        color: $accent-main;
      }
    }
  }

  &__title {
    @include fontSemibold18;
    color: $accent-main;
  }

  &__link {
    @include fontSemibold14;
    color: $white;
    background-color: $accent-main;
    border-width: 0;
    border-radius: 12px;
    padding: 12px 16px;
    transition: all 0.3s;
    cursor: pointer;

    &:focus,
    &:hover {
      background-color: $accent-hover;
    }

    &:disabled {
      background-color: $stroke;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &__review {
    @include fontRegular18;
    color: $text-dark;
    padding-right: 8px;
    max-height: 574px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
