@import '../../styles/utils/variables';

.page-container {
  margin: 0 auto;
  // max-width: 375px;
  max-width: 500px;
  padding: 48px 0 0;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 1100px) {
    padding: 80px 0;
    max-width: 1440px;
  }

  @media screen and (min-width: 1440px) {
    padding: 80px 10px;
    max-width: 1440px;
  }
}

.bg-container {
  min-height: 100vh;
  width: 100%;
  position: relative;
  background: $tags;

  @media screen and (min-width: 1100px) {
    width: calc(100vw - 12px);
  }
}

.support-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60vw;
  min-width: 375px;
  height: 70vh;
  min-height: 700px;
  margin: 0px auto;
}
